import { Component, OnInit } from '@angular/core';
import { FoldersService } from '../folders.service';
import { GlobalDataService } from '../global-data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public foldersService: FoldersService,
    public globalDataService: GlobalDataService) { 
    }

  ngOnInit() {
    this.globalDataService.activeFolderId = parseInt(localStorage.getItem('activeFolder'), 10);
  }

}
