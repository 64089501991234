import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotesService } from '../notes.service';
import { FoldersService } from '../folders.service';

export interface DialogData {
  id:number;
  name:string;
}

@Component({
  selector: 'app-folder-archive-dialog',
  templateUrl: './folder-archive-dialog.component.html',
  styleUrls: ['./folder-archive-dialog.component.scss']
})
export class FolderArchiveDialogComponent implements OnInit {

  constructor(
    public notesService: NotesService,
    public foldersService: FoldersService,
    public dialogRef: MatDialogRef<FolderArchiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmArchive() {
   this.notesService.archiveFolder(this.data.id);
   this.foldersService.update(this.data.id, {archived: true});
   this.dialogRef.close();
  }

}
