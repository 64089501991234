import {Injectable} from '@angular/core';
import Dexie from 'dexie';
import { DexieService } from './core/dexie.service';
import { FoldersService } from './folders.service';
import * as _ from 'underscore';

export interface Note {
  // body: string[];
  body: string;
  bodyArray?: string[];
  id?: number;
  order: number;
  topicIDs: number[];
  unixTimestamp?: any;
  archived?: string;
  searchRating?: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  table: Dexie.Table<Note, number>;
  _notes: Note[];
  searchResults: Note[];
  _searchTerms: [];

  set searchTerms(data) {
    // split string into array and filter out empty strings
    this._searchTerms = data.split(" ").filter(el => {
      return el != "";
    });
  }
  
  get notes(): Note[] {
    if (this._notes)
      return this._notes;
    else
      return [];
  }

  set notes(newList: Note[]) {
    this._notes = newList;
  }

  constructor(private dexieService: DexieService, private foldersService: FoldersService) {
    this.table = this.dexieService.table('notes');
  }

  searchNotes(term) {
    return this.table.where('bodyArray').startsWithIgnoreCase(term).toArray();
  }

  fillSearchResults() {
    if (this._searchTerms.length === 0)
      return this.notes = [];
      
    var results = [];
    this._searchTerms.forEach(term => {
      this.searchNotes(term).then((notesFromSearch) => {
        // filter out notes that are already in results
        notesFromSearch = notesFromSearch.filter(note => _.findIndex(results, {id: note.id}) === -1);
        // combine results wtih notesFromSearch
        results = [...results, ...notesFromSearch];
        // add search score to results
        results = this.scoreSearchResults(results);
        // display notes that are results
        this.notes = results;
      });
    });
  }

  scoreSearchResults(results) {
    results.forEach((note, index) => {
      this._searchTerms.forEach(term => {
        if (note.bodyArray.includes(term))
          (results[index].searchRating >= 0) ? results[index].searchRating++ : results[index].searchRating = 0;
      })
    })
    // sort the order so top rating appears visually at bottom
    return results.sort((a,b) => (a.searchRating > b.searchRating) ? 1 : -1);
  }

  transferNotes(fromTopicID, toTopicID) {
    var toppicIDArray = [parseInt(toTopicID, 10)];
    this.getNotesByTopic(fromTopicID).then((notes) => {
      notes.forEach(note => {
        this.update(note.id, {topicIDs: toppicIDArray})
      })
    });
  }

  archiveFolder(folderID: number) {
    this.getNotesByTopic(folderID).then((notes) => {
      notes.forEach(note => {
        this.update(note.id, {archived: 'true'})
      })
    });
  }

  updateOrder() {
    this._notes.forEach((value: Note, index: number) => {
      this._notes[index].order = index;
      this.update(value.id, {order: index});
    });
  }

  getAll() {
    return this.table.toArray();
  }

  add(data) {
    return this.table.add(data);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  remove(id) {
    return this.table.delete(id);
  }

  getNotesByTopic(id) {
    return this.table.where('topicIDs').equals(id).toArray();
  }

  getNotesArchived() {
    return this.table.where('archived').equals('true').toArray();
  }

}
