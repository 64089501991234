import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FoldersService, Topic } from '../folders.service';
import { GlobalDataService } from '../global-data.service';
import { MatDialog } from '@angular/material/dialog';
import { FolderActionsDialogComponent } from '../folder-actions-dialog/folder-actions-dialog.component';
import { FolderNameDialogComponent } from '../folder-name-dialog/folder-name-dialog.component';
import { FolderArchiveDialogComponent } from '../folder-archive-dialog/folder-archive-dialog.component';

@Component({
  selector: 'app-folder-nav',
  templateUrl: './folder-nav.component.html',
  styleUrls: ['./folder-nav.component.scss']
})
export class FolderNavComponent implements OnInit {

  @Output() changeActiveTopicID = new EventEmitter<number>();
  @Input('activeTopicID') activeTopicID: number;

  constructor(
    public foldersService: FoldersService,
    public router: Router,
    public globalDataService: GlobalDataService,
    public dialog: MatDialog) {}

  ngOnInit() {
    this.getAllTopics();
  }

  menuOpened(id: number) {
    var el = document.querySelector('.topic-'+id+' .topic__more');
    el.classList.add('focus');
  }

  menuClosed(id: number) {
    var el = document.querySelector('.topic-'+id+' .topic__more');
    el.classList.remove('focus');
  }

  addNewFolder(name: string) {
    const topic: Topic = {
      name: name
    };

    this.foldersService
      .add(topic)
      .then((id) => {
        this.foldersService.topics = [...this.foldersService.topics, Object.assign({}, topic, { id })];
        this.activateTopic(id);
      });

    this.foldersService.newFolderDisplay = false;
  }

  ngAfterViewChecked() {
    if (this.foldersService.newFolderDisplay === true) {
      document.getElementById('newFolderName').focus();
      // @ts-ignore
      document.getElementById('newFolderName').select();
    }
  }

  archiveFolderDialog(id, name) {
    const dialogRef = this.dialog.open(FolderArchiveDialogComponent, {
      width: '450px',
      data: {id: id, name: name}
    });

    dialogRef.afterClosed().subscribe(data => {
      this.getAllTopics();
      this.foldersService.getAll().then(topics => {
        var activeTopic = undefined;
        var i = 0;

        while(activeTopic === undefined) {
          if (topics[i].archived != true)
            activeTopic = topics[i].id;
          i++;
        }

        if (activeTopic)
          this.activateTopic(activeTopic);
      })
    });
  }

  editNameDialog(id, name) {
    const dialogRef = this.dialog.open(FolderNameDialogComponent, {
      width: '450px',
      data: {id: id, name: name}
    });

    dialogRef.afterClosed().subscribe(data => {
      this.getAllTopics();
    });
  }

  rmFolderDialog(id, name) {
    const dialogRef = this.dialog.open(FolderActionsDialogComponent, {
      width: '450px',
      data: {id: id, name: name}
    });

    dialogRef.afterClosed().subscribe(data => {
      this.foldersService.getAll().then(topics => {
        this.activateTopic(topics[0].id);
      })
    });
  }

  getAllTopics() {
    this.foldersService.getAll().then((topics: Array<Topic>) => {
      if (topics.length === 0)
        this.addInitialTopic()

      this.foldersService.topics = topics;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeTopicID) {
      this.getAllTopics();
    }
  }

  addInitialTopic() {
    const topic: Topic = {
      name: 'General'
    };

    this.foldersService
      .add(topic)
      .then((id) => {
        this.foldersService.topics = [...this.foldersService.topics, Object.assign({}, topic, { id })];
        this.activateTopic(id);
      });
  }

  activateTopic(id) {
    this.globalDataService.activeFolderId = id;
  }
}
