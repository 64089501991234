import { Injectable } from '@angular/core';
import {BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {
  
  _activeFolderId: number;
  _activeFolder$ = new BehaviorSubject(this._activeFolderId);

  constructor() { }

  get activeFolderId() {
    return this._activeFolderId;
  }

  get activeFolderId__Observable() {
    return this._activeFolder$.asObservable();
  }

  set activeFolderId(id: number) {
    this._activeFolderId = id;
    this._activeFolder$.next(this._activeFolderId);
    localStorage.setItem('activeFolder', id+'');
  }

}
