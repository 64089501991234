import { Component, OnInit } from '@angular/core';
import { NotesService } from '../notes.service';

@Component({
  selector: 'app-page-archive',
  templateUrl: './page-archive.component.html',
  styleUrls: ['./page-archive.component.scss']
})
export class PageArchiveComponent implements OnInit {

  constructor(public notesService: NotesService) { }

  ngOnInit() {
  }

}
