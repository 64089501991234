import { Component, OnInit } from '@angular/core';
import { NotesService, Note } from '../notes.service';

@Component({
  selector: 'app-page-search',
  templateUrl: './page-search.component.html',
  styleUrls: ['./page-search.component.scss']
})
export class PageSearchComponent implements OnInit {

  constructor(public notesService: NotesService) { }

  ngOnInit() {
  }

}