import 'reflect-metadata';
import '../polyfills';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule  } from '@angular/material/list';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CoreModule } from './core/core.module';


import { AppRoutingModule } from './app-routing.module';

// NG Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { PageArchiveComponent } from './page-archive/page-archive.component';


// copied from home module
import { HomeComponent } from './home/home.component';
import { MainInputComponent } from './main-input/main-input.component';
import { NotesListComponent } from './notes-list/notes-list.component';
import { SharedModule } from './shared/shared.module';
import { FolderActionsDialogComponent } from './folder-actions-dialog/folder-actions-dialog.component';
import { FolderNameDialogComponent } from './folder-name-dialog/folder-name-dialog.component';
import { FolderArchiveDialogComponent } from './folder-archive-dialog/folder-archive-dialog.component';
import { SidebarFooterComponent } from './sidebar-footer/sidebar-footer.component';
import { FolderNavComponent } from './folder-nav/folder-nav.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { SidebarComponent } from './sidebar/sidebar.component';

import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ArchiveListComponent } from './archive-list/archive-list.component';
import { PageSearchComponent } from './page-search/page-search.component';
import { SearchListComponent } from './search-list/search-list.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PageArchiveComponent,
    HomeComponent,
    MainInputComponent,
    NotesListComponent,
    FolderActionsDialogComponent,
    FolderNameDialogComponent,
    FolderArchiveDialogComponent,
    SidebarFooterComponent,
    FolderNavComponent,
    TitleBarComponent,
    SidebarComponent,
    ArchiveListComponent,
    PageSearchComponent,
    SearchListComponent,
  ],
  imports: [
    MatChipsModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    DragDropModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [FolderActionsDialogComponent, FolderNameDialogComponent,FolderArchiveDialogComponent]
})
export class AppModule {}
