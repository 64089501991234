import { Component, OnInit, CollectionChangeRecord } from '@angular/core';
import { FoldersService } from '../folders.service';
import { NotesService } from '../notes.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent implements OnInit {

  constructor(
    public foldersService: FoldersService,
    public notesService: NotesService,
    public router: Router) {}

  ngOnInit() {}

  activateSearch() {
    this.router.navigate(['/search']);
  }

  addFolder() {
    this.foldersService.newFolderDisplay = true;
    this.addEventListener();
  }

  addEventListener() {
    document.addEventListener('click', event => {
      // if clicked el is not add folder btn or add folder input,
      // then remove event listener and remove new folder input
      // @ts-ignore
      if (!event.target.matches('#addFolderBtn, #addFolderBtn *, #newFolderName')) {
          this.foldersService.newFolderDisplay = false;
          this.rmEventListener;
      }
    }, false);
  }

  rmEventListener() {
    // @ts-ignore
    removeEventListener('click', document);
  }


}