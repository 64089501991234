import { Component, OnInit, Input } from '@angular/core';
import { NotesService, Note } from '../notes.service';
import { FoldersService, Topic } from '../folders.service';
import { GlobalDataService } from '../global-data.service';

import * as moment from 'moment';

@Component({
  selector: 'app-main-input',
  templateUrl: './main-input.component.html',
  styleUrls: ['./main-input.component.scss']
})
export class MainInputComponent implements OnInit {

  _noteInputModel = "";

  set noteInputModel(value: string) {
    if ((new RegExp('#')).test(value)) {
      value = value.replace("#", "yup");
    }
    this._noteInputModel = value;
  }

  initiateHashtag(e: any) {
    console.log(e);
    console.log('hashtag');
  }

  @Input('activeTopicID') activeTopicID: number;

  constructor(
    private notesService: NotesService,
    private foldersService: FoldersService,
    private globalDataService: GlobalDataService
  ) {}

  ngOnInit() {
    // Strip out css when user copy + pastes into main text input
    document.querySelector('div.main-input').addEventListener("paste", function(e: any) {
      // cancel paste
      e.preventDefault();
      // get text representation of clipboard
      var text = e.clipboardData.getData('text/plain');
      // insert text manually
      document.execCommand("insertHTML", false, text);
    });
  }

  mainInputKeyDown(e: any){
    // Prevent div from changing height when just press enter key
    if (e.keyCode == 13 && !e.shiftKey)
      e.preventDefault();
  }

  onNoteInput(e: any) {
    // if  enter key (sans shiftkey)
    if (e.keyCode == 13 && !e.shiftKey) {
      this.processNote(this._noteInputModel);
      document.querySelector('div.main-input--note').innerHTML = '';
      this.noteInputModel = "";
      return;
    }

    this.noteInputModel = document.querySelector('div.main-input--note').innerHTML;
  }

  processNote(body: string) {
    if (body == '')
      return

    const note: Note = {
      bodyArray: body.split(" "), // convert body string to an array for search
      body: body,
      order: this.notesService.notes.length + 1,
      topicIDs: [this.globalDataService._activeFolderId],
      unixTimestamp: moment().unix()
    };

    this.notesService
      .add(note)
      .then((id) => {
        this.notesService.notes = [...this.notesService.notes, Object.assign({}, note, { id })];
      });
  }

}
