import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FoldersService, Topic } from '../folders.service';
import { NotesService } from '../notes.service';

export interface DialogData {
  id:number;
  name:string;
}

@Component({
  selector: 'app-folder-actions-dialog',
  templateUrl: './folder-actions-dialog.component.html',
  styleUrls: ['./folder-actions-dialog.component.scss']
})
export class FolderActionsDialogComponent implements OnInit {

  removeFolderOption: string;
  attributeToFolderID: number = this.foldersService.topics[0].id; // default is first one

  constructor(
    public foldersService: FoldersService,
    public notesService: NotesService,
    public dialogRef: MatDialogRef<FolderActionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
    if (this.attributeToFolderID == this.data.id) {
      if (this.foldersService.topics[1])
        this.attributeToFolderID = this.foldersService.topics[1].id;
    }
  }

  onlyFolder() {
    var onlyFolder = true;

    this.foldersService.topics.forEach(topic => {
      if (topic.id == this.foldersService.activeTopicID || topic.archived == true) {
        return;
      } else {
        onlyFolder = false;
      }
    });

    return onlyFolder;
  }

  onNoClick(): void {
    console.log(this.attributeToFolderID);
    this.dialogRef.close();
  }

  rmTopic(id: number) {
    if (this.removeFolderOption == 'attribute-notes') {
      this.notesService.transferNotes(id, this.attributeToFolderID);
    }

    this.foldersService
    .remove(id)
    .then(() => {
      this.foldersService.topics = this.foldersService.topics.filter((topic:Topic) => topic.id !== id);
      this.dialogRef.close();
    });
  }

}