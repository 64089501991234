import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FoldersService, Topic } from '../folders.service';

export interface DialogData {
  id:number;
  name:string;
}

@Component({
  selector: 'app-folder-name-dialog',
  templateUrl: './folder-name-dialog.component.html',
  styleUrls: ['./folder-name-dialog.component.scss']
})
export class FolderNameDialogComponent implements OnInit {

  folderName: string = this.data.name;

  constructor(
    public foldersService: FoldersService,
    public dialogRef: MatDialogRef<FolderNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmNameChange() {
    this.foldersService.update(this.data.id, {name: this.folderName})
    .then(() => {
      this.dialogRef.close();
    });
  }

}