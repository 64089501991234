import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NotesService, Note } from '../notes.service';
import { FoldersService } from '../folders.service';
import { GlobalDataService } from '../global-data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss'],
  // Need to remove view encapsulation so that the custom tooltip style defined in
  // `tooltip-custom-class-example.css` will not be scoped to this component's view.
  encapsulation: ViewEncapsulation.None
})
export class SearchListComponent implements OnInit {

  constructor(
    public notesService: NotesService,
    public foldersService: FoldersService,
    public globalDataService: GlobalDataService) {
    }

  ngOnInit() {
    this.notesService.notes = this.notesService.searchResults;
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
    // this.replacePaste();
  }

  get now() {
    return moment().format('ll');
  }

  removeTerm(searchTerm) {
    console.log('removed', searchTerm);
  }

  scrollToBottom() {
    // scrolls to bottom of div so you see latest notes first
    const el = document.getElementById("notes-list");
    el.scrollTop = el.scrollHeight;
  }

  replacePaste() {
    // TODO: make a service
    // Strip out css when user copy + pastes into main text input
    document.querySelector('.note__body').addEventListener("paste", function(e: any) {
      // cancel paste
      e.preventDefault();
      // get text representation of clipboard
      var text = e.clipboardData.getData('text/plain');
      // insert text manually
      document.execCommand("insertHTML", false, text);
    });
  }

  formatDate(unixTimestmap) {
    if (unixTimestmap)
      // return moment.unix(unixTimestmap).format("MM/DD/YYYY h:mm:ss A");
      return moment.unix(unixTimestmap).calendar();
      // format("LLl");
  }

  editNote(event: any, noteID: number) {
    const content = event.target.innerHTML;
    this.notesService.update(noteID, {body: content});
  }

  archiveNote(noteID: number) {
    this.notesService.update(noteID, {archived: 'true'}).then(x => {
    })
  }

}