import { Component, OnInit } from '@angular/core';
import { NotesService, Note } from '../notes.service';
import * as moment from 'moment';

@Component({
  selector: 'app-archive-list',
  templateUrl: './archive-list.component.html',
  styleUrls: ['./archive-list.component.scss']
})
export class ArchiveListComponent implements OnInit {

  constructor(public notesService: NotesService ) { }

  ngOnInit() {
    this.notesService.getNotesArchived().then((notes) => {
      this.notesService.notes = notes;
      console.log(notes);
    });
  }

  scrollToBottom() {
    // scrolls to bottom of div so you see latest notes first
    const el = document.getElementById("notes-list");
    el.scrollTop = el.scrollHeight;
  }

  get now() {
    return moment().format('ll');
  }

  formatDate(unixTimestmap) {
    if (unixTimestmap)
      // return moment.unix(unixTimestmap).format("MM/DD/YYYY h:mm:ss A");
      return moment.unix(unixTimestmap).format("h:mm a");
  }

  editNote(event: any, noteID: number) {
    const content = event.target.innerHTML;
    this.notesService.update(noteID, {body: content});
  }

  rmNote(id) {
    this.notesService
    .remove(id)
    .then(() => {
      this.notesService.notes = this.notesService.notes.filter((note:Note) => note.id !== id);
    });
  }

}
