import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { DexieService } from './core/dexie.service';

export interface Topic {
  name: string;
  id?: number;
  order?: number;
  archived?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FoldersService {
  table: Dexie.Table<Topic, number>;
  _topics: Topic[];
  activeTopicID: number;
  newFolderDisplay = false;
  showArchive = false;

  constructor(private dexieService: DexieService) {
    this.table = this.dexieService.table('topics');
  }

  get topics(): Topic[] {
    this._topics;
    return this._topics;
  }

  set topics(newList: Topic[]) {
    this._topics = newList.sort((a, b) => (a.order > b.order) ? 1 : -1);
  }

  toggleArchive(){
    this.showArchive = this.showArchive ? false : true;
  }

  updateOrder() {
    this._topics.forEach((value: Topic, index: number) => {
      this._topics[index].order = index;
      this.update(value.id, {order: index});
    });
  }

  getAll() {
    return this.table.toArray();
  }

  add(data) {
    return this.table.add(data);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  remove(id) {
    return this.table.delete(id);
  }
}
