import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../global-data.service';

@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './sidebar-footer.component.html',
  styleUrls: ['./sidebar-footer.component.scss']
})
export class SidebarFooterComponent implements OnInit {

  constructor(public globalDataService: GlobalDataService) { }

  ngOnInit() {
  }

  dlArchived() {
    console.log('delete archived');
  }

}
